@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  background-color: #89CFF0;
  font-family: "Figtree", sans-serif;
  height: 100vh;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.sign-in-container form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: rgb(37, 41, 46);
  padding: 30px;
  margin: auto;
}

.sign-in-container form input {
  width: 250px;
  padding: 20px;
  margin: 0 20px 20px 20px;
  background-color: rgb(242, 242, 242);
  border: none;
  border-radius: 5px;
  font-size: 18px;
}

.sign-in-container form button {
  width: 200px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #89CFF0;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
}

.sign-in-container form button:hover {
  background-color: #5DADE2;
  transition: .2s ease-in-out;
}

.mainLetter {
  background-color: white;
  color: rgb(87, 98, 112);
  padding: 20px;
  width: 100%;
  max-width: 80vw;
  text-align: left;
  line-height: 2;
  font-weight: 600;
  max-height: 500px;
  overflow: auto;
  border: #85a0ad 5px solid;
}

.mainLetter p {
  font-size: 20px;
}

.signout {
  padding: 15px;
  border: 0;
  border-radius: 5px;
  background-color: white;
  font-weight: bold;
  color: #3a90ba;
  font-size: 20px;
}
.signout:hover {
  background-color: rgb(209, 237, 255);
  color: rgb(137, 97, 211);
  transition: .2s ease-in-out;
}